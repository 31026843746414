<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card class="elevation-3">
    <v-col lg="12" class="px-0 px-md-3">
      <v-card v-if="isMobile" flat>
        <v-row no-gutters class="pa-2">
          <v-col cols="9">
            <v-card-title class="headline display-1">
              Interventions
            </v-card-title>
          </v-col>
          <v-col cols="3" md="6" class="mt-3 pr-2">
            <v-row justify="center">
              <v-dialog v-model="docFilterDialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Filtres
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Filtres</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="par statut"
                            :items="status"
                            clearable
                            v-model="selectedStatus"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            :label="$t('tableStatus.by_category')"
                            :items="categories"
                            clearable
                            v-model="selectedCategory"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="docFilterDialog = false"
                      >OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>

          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          ></v-text-field>
        </v-row>
      </v-card>

      <v-card flat v-else>
        <v-card-title class="headline display-1">
          Liste des demandes de support
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text class="elevation-2 grey mt-n2 lighten-5 pt-2">
          <v-row>
            <v-col md="6" sm="12" class="mt-n5 mb-n5">
              <v-select
                label="Filtrer par statut"
                :items="status"
                clearable
                v-model="selectedStatus"
                multiple
                chips
              ></v-select>
            </v-col>
            <v-col md="6" sm="12" class="mt-n5 mb-n5">
              <v-select
                :label="$t('tableStatus.by_category')"
                :items="categories"
                clearable
                multiple
                chips
                v-model="selectedCategory"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="px-2 px-md-4" :id="tableId">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          item-key="id"
          :loading="loading"
          @click:row="clicked"
          class="table is_bordered"
          sort-by="date"
          sort-desc
          :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
          :group-desc="false"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item="{ item, isMobile }">
            <mobile-row
              :item="item"
              :headers="mobileHeaders"
              v-if="isMobile"
              @expand="expandRow"
              :is-expanded="shoulExpand(item.id)"
              @n-expand="removeFromExpanded"
              @row-clicked="clicked"
            >
              <template v-slot:id="{ item }">
                {{ formatID(item.id) }}
              </template>
              <template v-slot:statusName="{ item }">
                <v-chip
                  label
                  small
                  :color="getStatus(item.status).color"
                  text-color="white"
                >
                  {{ getStatus(item.status).name }}
                </v-chip>
              </template>
              <template v-slot:content="{ item }">
                <div
                  class="incident description"
                  :class="{ 'test-class': isMobile }"
                  v-if="item.content"
                  v-html="decodeContent(item.content)"
                ></div>
              </template>
              <template v-slot:last_comment="{ item }">
                <div
                  v-if="item.last_comment"
                  class="incident comments"
                  v-html="decodeContent(item.last_comment)"
                ></div>
              </template>
              <template v-slot:itilcategories_id="{ item }">
                {{
                  item.itilcategories_id === 0
                    ? "Non Défini"
                    : item.itilcategories_id
                }}
              </template>
              <template v-slot:emailfield="{ item }">
                {{ item.contact_mail ? item.contact_mail : "" }} <br />
                {{ item.contact_tel ? formatTel(item.contact_tel) : "" }}
              </template>
              <template v-slot:date="{ item }">
                {{
                  item.date ? moment(item.date).format("DD-MM-YYYY HH:mm") : ""
                }}
              </template>
              <template v-slot:solvedate="{ item }">
                {{
                  item.solvedate
                    ? moment(item.solvedate).format("DD-MM-YYYY HH:mm")
                    : ""
                }}
              </template>
            </mobile-row>
            <c-row
              v-else
              :item="item"
              :headers="headers"
              rtl
              @click="clicked(item)"
            >
              <template v-slot:id="{ item }">
                {{ formatID(item.id) }}
              </template>
              <template v-slot:name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:statusName="{ item }">
                <v-chip
                  label
                  small
                  :color="getStatus(item.status).color"
                  text-color="white"
                >
                  {{ getStatus(item.status).name }}
                </v-chip>
              </template>
              <template v-slot:content="{ item }">
                <div
                  class="incident description"
                  :class="{ 'test-class': isMobile }"
                  v-if="item.content"
                  v-html="decodeContent(item.content)"
                ></div>
              </template>
              <template v-slot:priority="{ item }">
                {{ item.priority }}
              </template>
              <template v-slot:last_comment="{ item }">
                <div
                  class="incident comments"
                  v-html="decodeContent(item.last_comment)"
                ></div>
              </template>

              <template v-slot:itilcategories_id="{ item }">
                <div>
                  <v-edit-dialog
                    :return-value.sync="item.itilcategories_id"
                    @save="saveCategories(item, item.itilcategories_id)"
                  >
                    <v-chip
                      :color="
                        item.itilcategories_id === 'Public'
                          ? 'primary'
                          : 'secondary'
                      "
                      text-color="white"
                    >
                      {{
                        item.itilcategories_id === 0
                          ? "Non Défini"
                          : item.itilcategories_id
                      }}</v-chip
                    >
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Catégorie</div>
                      <v-combobox
                        v-model="item.itilcategories_id"
                        autofocus
                        solo
                        :items="selectedCategory"
                        outlined
                      >
                      </v-combobox>
                    </template>
                  </v-edit-dialog>
                </div>
              </template>

              <template v-slot:date="{ item }">
                {{
                  item.date ? moment(item.date).format("DD-MM-YYYY HH:mm") : ""
                }}
              </template>
              <template v-slot:solvedate="{ item }">
                {{
                  item.solvedate
                    ? moment(item.solvedate).format("DD-MM-YYYY HH:mm")
                    : ""
                }}
              </template>

              <template v-slot:emailfield="{ item }">
                {{ item.contact_mail ? item.contact_mail : "" }} <br />
                {{ item.contact_tel ? formatTel(item.contact_tel) : "" }}
              </template>
            </c-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
        transition="dialog-bottom-transition"
        content-class="ticket-dialog"
        :attach="'#' + tableId"
        :key="tableId"
      >
        <v-card>
          <v-toolbar dark class="title" color="primary">
            Suivi d'intervention :
            {{ selectedTicket ? selectedTicket.name : "" }}
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dismissDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <ticket-detail
            :ticket-id="selectedTicket.id"
            :ticket-name="selectedTicket.name"
            :ticket-date="selectedTicket.date"
            v-if="selectedTicket"
          ></ticket-detail>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import {
  statusList,
  formatID,
  formatTel,
  remove,
  headers,
  mobileHeaders,
  loadStates
} from "../../utils";
import { Decoder } from "../../mixins/decodeContent";
import TicketDetail from "./TicketDetail";
import moment from "../../plugins/moment";
import MobileRow from "../MobileRow";
import { mapGetters } from "vuex";
import { LocalMixin } from "../../mixins/local";
import { TiptapMixin } from "../../mixins/tiptap";
import TICKET_API from "../../api/ticket";

export default {
  name: "Incident",
  props: {
    tickets: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: String,
      default: "incidentTable"
    }
  },
  components: { TicketDetail, MobileRow },
  data() {
    return {
      list: [],
      search: "",
      expanded: [],
      headers: headers(this.local),
      mobileHeaders: mobileHeaders(this.local),
      docFilterDialog: false,
      selectedTicket: undefined,
      dialog: false,
      //status: statusList(this.$root.$i18n.locale).map(stat => stat.name),  CCA: je ne veux pas les clos
      status: ["Nouveau", "Attribué", "En cours", "Planifié", "Résolu"],
      selectedStatus: ["Nouveau", "Attribué", "En cours", "Planifié"],
      categories: ["BUG", "FEAT"],
      selectedCategory: ["BUG", "FEAT"],
      loadStates,
      page: 1,
      pageCount: 0,
      snack: false,
      snackColor: "",
      snackText: ""
    };
  },
  mixins: [Decoder, LocalMixin, TiptapMixin],
  mounted() {
    this.list = this.tickets.filter(el => el.itemtypeID == this.$route.params.itemtypeId);
    this.loadLastComment();
  },
  methods: {
    formatID,
    formatTel,
    moment,
    getStatus(status) {
      return status
        ? statusList(this.$root.$i18n.locale).find(el => el.status === status)
        : { name: "", color: "" };
    },
    clicked: function(row) {
      this.selectedTicket = row;
      this.dialog = true;
      /*this.$router.push({
        name: "lots.ticket.show",
        params: { id: this.$route.params.id, ticketId: row.id }
      });*/
    },
    dismissDialog() {
      this.dialog = false;
      this.selectedTicket = undefined;
    },
    customSort(items, index, sortDesc) {
      // The following is informations as far as I researched.
      // items: 'tickets' items
      // index: Enabled sort headers value. (black arrow status).
      // sortDesc: Whether enabled sort headers is desc
      if (!index[1]) return items;

      items.sort((a, b) => {
        const attribute = index[1];
        let sortA = a[attribute];
        let sortB = b[attribute];
        const dateAttributes = [
          "date",
          "solvedate",
          "attributdate",
          "date_mod"
        ];
        if (dateAttributes.includes(attribute)) {
          if (!sortA) {
            sortA = "2010-01-01 00:00";
          }
          if (!sortB) {
            sortB = "2010-01-01 00:00";
          }
          const dateA = moment(sortA, "YYYY-MM-DD HH:mm:ss");
          const dateB = moment(sortB, "YYYY-MM-DD HH:mm:ss");

          if (sortDesc[0]) {
            if (dateB.isAfter(dateA)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          } else {
            if (dateA.isAfter(dateB)) {
              return 1;
            } else if (dateB.isAfter(dateA)) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });

      return items;
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    },
    titleText(selectedTicket) {
      try {
        const title = this.getActiveEntity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        let assetTitle = "";
        if (this.$vuetify.breakpoint.smAndDown) {
          assetTitle =
            parentsEntities[3].slice(0, 10) +
            " > " +
            parentsEntities[4].slice(0, 10);

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment.slice(0, 10);
        } else {
          assetTitle = parentsEntities[3] + " > " + parentsEntities[4];

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment;
        }
        return assetTitle;
      } catch (error) {
        return "";
      }
    },
    loadLastComment() {
      this.list.forEach(async ticket => {
        ticket.last_comment = "";

        const ticketID = ticket.id;
        const followUps = this.getAllITILFollowups.filter(
          fl => fl.items_id === ticketID
        );
        const tasks = this.getTicketsTasks.filter(
          fl => fl.tickets_id === ticketID
        );
        const solutions = this.getAllITILSolutions.filter(
          fl => fl.items_id === ticketID
        );

        let alldata = followUps.concat(tasks).concat(solutions);

        let sortedData = alldata.sort((a, b) => {
          if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
            return -1;
          } else if (moment(b.date_creation).isAfter(moment(a.date_creation))) {
            return 1;
          } else {
            return 0;
          }
        });

        const lastComment = sortedData[0] ? sortedData[0].content : null;

        ticket.last_comment = lastComment;
      });
    },
    saveName(item, value) {
      TICKET_API.changeName(
        item.id,
        item.itemtype,
        item.itemtypeID,
        value,
        item.contact_mail,
        item.contact_tel
      ).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    saveContent(item, value) {
      TICKET_API.changeDescription(item.id, value).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    saveCategories(item, value) {
      TICKET_API.changeCategories(item.id, value).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    },
    savePriority(item, value) {
      TICKET_API.changePriority(item.id, value).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data Updated";
      });
    }
  },
  computed: {
    ...mapGetters([
      "getActiveEntity",
      "getAllITILSolutions",
      "getAllITILFollowups",
      "getTicketsTasks",
      "getTypeName"
    ]),
    filteredItems() {
      return this.list.filter(i => {
        if (i.statusName !== "Clos") {
          if (i.itilcategories_id === 0) {
            i.itilcategories_id = "Non défini";
          }
          return (
            ((this.selectedStatus.length === 0 ||
              this.selectedStatus.includes(i.statusName)) &&
              (this.selectedCategory.length === 0 ||
                this.selectedCategory.includes(i.itilcategories_id))) ||
            (!i.id && i.equipment)
          );
        }
      });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    },
    filteredItemsName() {
      return this.filteredItems.filter(el => !!el.name).map(item => item.name);
    }
  },
  watch: {
    $route() {
      this.list = this.tickets;
      this.loadLastComment();
    },
    tickets() {
      this.list = this.tickets;
      this.loadLastComment();
    }
  }
};
</script>
<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

:root {
  --max-height: 100px;
  --desc-max-width: 350px;
  --content-max-width: 200px;
  --primary: #44b02a;
}

tr:nth-of-type(even) {
  background-color: #f5f5f5;
}

.t-row {
  color: #2d2d2d;
}
.table.is-bordered tr:last-child th {
  border-bottom-width: 1px !important;
}
.table.is-bordered th {
  border-width: 1px !important;
  color: #363636 !important;
}
@media only screen and (max-width: 799px) and (min-width: 240px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    border: 1px solid #dbdbdb !important;
    padding: 0.5em 0.75em !important;
    vertical-align: middle !important;
    background-color: var(--primary);
    color: white !important;
  }
}
.v-data-table table td {
  border-width: 1px !important;
  border: 1px solid #dbdbdb !important;
  vertical-align: middle !important;
}
.incident p {
  margin-bottom: 0 !important;
}
.resolu {
  color: white !important;
}
.description {
  max-width: var(--desc-max-width);
  max-height: var(--max-height);
  overflow: auto;
}

.comments {
  max-width: var(--content-max-width);
  max-height: var(--max-height);
  overflow: auto;
}
</style>
