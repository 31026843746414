<template>
  <default-view v-if="isDirectLink" class="mt-n6">
    <v-dialog
      ref="crudDialog"
      v-model="addEventDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mt-n12" color="primary" dark v-bind="attrs" v-on="on">
          AJOUTER UNE DEMANDE
        </v-btn>
      </template>
      <add-support-form
        :overlay="overlay"
        @canceled="closeEventDialog"
        @addSupport="addSupport"
      ></add-support-form>
    </v-dialog>
    <v-row
      align="start"
      no-gutters
      justify="center"
      class="fill-height mt-n6 mx-n3"
    >
      <v-col cols="12">
        <onglet-technique> </onglet-technique>
      </v-col>
    </v-row>
  </default-view>
</template>
<script>
import OngletTechnique from "../components/support/Onglet";
import DefaultView from "../components/DefaultView";
import AddSupportForm from "../components/support/AddSupport.vue";
import TICKET_API from "../api/ticket";
import Swal from "sweetalert2";
import USER_API from "../api/user";
import axios from "../plugins/axios";

export default {
  components: { OngletTechnique, DefaultView, AddSupportForm },
  data: () => ({
    overlay: false,
    addEventDialog: false
  }),
  methods: {
    addSupport({ input }) {
      let cat = input.category == "BUG" ? 9 : 10;
      const payload = {
        name: JSON.stringify({
          itemtype: this.$route.params.itemtype,
          itemtypeID: this.$route.params.itemtypeId,
          titre: input.title,
          mail: input.email,
          tel: input.tel
        }),
        requesttypes_id: 7,
        type: 1,
        locations_id: this.$route.params.itemtypeId,
        itilcategories_id: cat,
        content: input.content,
        entities_id: this.$route.params.id,
        is_visible: 1,
      };
      this.overlay = true;
      if (input.photo) {
        USER_API.setActiveEntity(this.$route.params.id).then(() => {
            TICKET_API.createTicket(payload)
              .then(({ data }) => {
                this.photoLoading = true;
                this.overlay = true;
                const image = input.exifimg;
                let ticketid = data.id;
                TICKET_API.addImageDoc(image, input.photo.src)
              .then(({ data }) => {
                TICKET_API.attachDocumentToItem(ticketid, data.id)
                  .then(async () => {
                  })
                  .catch(({ response }) => {
                    console.log(response.data[1])
                    this.commentError = response.data;
                    this.setError(response.data[1]);
                    this.photoLoading = false;
                    this.overlay = false;
                  });
              })
              .catch(({ response }) => {
                axios.defaults.headers["Content-Type"] = "application/json";
                this.commentError = response.data;
                this.setError(response.data[1]);
              });
          })
        .then(() => {
          this.addEventDialog = false;
          this.overlay = false;
          if (this.isDirectLink) {
            Swal.fire({
              title: "Merci de votre demande!",
              text: "Vous allez recevoir un mail de suivi",
              icon: "success",
              showConfirmButton: false
            });
          } else {
            Swal.fire(
              "Merci de votre demande!",
              "Vous allez recevoir un mail de confirmation",
              "success"
            );
          }
        })
        .finally(() => {
          if (this.isDirectLink) {
            setTimeout(function() {
              //window.location.reload();
            }, 4000);
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops... pas top",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Error de creation"
          });
        });
        });
      } else {
        TICKET_API.createTicket(payload)
        .then(() => {
          this.addEventDialog = false;
          this.overlay = false;
          if (this.isDirectLink) {
            Swal.fire({
              title: "Merci de votre demande!",
              text: "Vous allez recevoir un mail de suivi",
              icon: "success",
              showConfirmButton: false
            });
          } else {
            Swal.fire(
              "Merci de votre demande!",
              "Vous allez recevoir un mail de confirmation",
              "success"
            );
          }
        })
        .finally(() => {
          if (this.isDirectLink) {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Error de creation"
          });
        });
      }    
    },
    closeEventDialog() {
      this.addEventDialog = false;
    }
  },
  computed: {
    isDirectLink() {
      return this.$route.meta.directLink;
    }
  }
};
</script>
